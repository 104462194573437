import { Address } from "./User";

export enum PreferrredDay {
  "START" = "Monday - Wednesday",
  "MID" = "Wednesday - Friday",
  "END" = "Saturday - Sunday",
  "ANY" = "Anyday",
}

export enum PreferredTime {
  "MORNING" = "9am - 12pm",
  "AFTERNOON" = "12pm - 4pm",
  "EVENING" = "4pm - 9pm",
}

export enum Status {
  "PEND" = "Pending",
  "CANCEL" = "Cancel",
  "DEC" = "Decline",
  "PLAN" = "Scheduled",
  "DISP" = "Collection",
  "COM" = "Completed",
}

export enum ProcessType {
  "CREATED" = "Schedule has been created",
  "CANCELLED" = "Schedule has been cancelled",
  "DECLINED" = "Schedule has been declined",
  "SCHEDULED" = "Schedule has been scheduled",
  "DISPATCHED" = "Collection vehicle has been dispatched",
  "COMPLETED" = "Schedule has been completed",
}

/**
 * @interface Process
 * @description Process interface to document schedule progress
 *
 * type - Process type interface
 * date - Date of process created
 * description - Description for different meaning (Decline Reason - DECLINED/ Plate number - DISPATCHED/ Schedule Date - SCHEDULED / Purchase Amount - COMPLETED )
 * amount - Amount of used cooking oil collected - Only applicated when type === COMPLETED
 */
export interface Process {
  type: keyof typeof ProcessType;
  date: Date;
  description?: string;
  amount?: number;
}

/**
 * @collection schedules
 * @description Appointment made for recycling used cooking oil
 *
 * id - Auto generated uuid
 * userId - Firebase Auth Id for user that creates the appointment
 * phone - Phone of the schedule
 * address - Address of the schedule
 * image - Token of the schedule image stored at schedules/${scheduleID}/preview.jpg
 * updatedAt - Date of schedules updated
 * status - Status interface
 * preferredDay - Preferred date interface
 * preferredTime - Preferred time interface
 * additionRemark - Additional remark of schedule
 * estimatedWeight - Estimated weight of the schedule
 * processes - Processes list
 * collectedWeight - Collected weight
 * collectedPayment - Payment made to users in RM
 * collectedPerKg - Payment made to users in RM per kg
 * travelDistance - Distance travelled for each schedule in (km)
 * startPoint - Optional address field for start point
 * endPoint - Optional address field for end point
 * promoCode - Promotion code applied to schedule
 * assignedAgentId - Assigned agent admin
 * createdBy - Medium for creating schedules
 */
export interface ScheduleModel {
  id: string;
  userId: string;
  phone: string;
  address: Address;
  image: string;
  updatedAt: Date;
  status: keyof typeof Status;
  preferredDay: keyof typeof PreferrredDay;
  preferredTime: keyof typeof PreferredTime;
  additionRemark: string;
  estimatedWeight: number;
  processes: Process[];
  collectedWeight?: number;
  collectedPayment?: number;
  collectedPerKg?: number;
  paymentMethod?: "Cash" | "Bank" | "Points";
  signature?: string;
  travelDistance?: number;
  startPoint?: Address;
  endPoint?: Address;
  eventCode?: string;
  promoCode?: string;
  assignedDriverId?: string;
  assignedAgentId?: string;
  collectedBy?: string;
  createdBy?: "brunei" | "web" | "app";
}
