import axios from "axios";
import firebase from "firebase";
import moment from "moment";
import Ngo from "../../../models/Ngo";
import { PendingTransactionModel } from "../../../models/PendingTransaction";
import Reward from "../../../models/Reward";
import { TransactionModel } from "../../../models/Transaction";
import { UserModel } from "../../../models/User";

export const pendTransactionDataLimit = 10;

export const getPendingTransactionWithPagination = (
  paginationStartAfter: string | Date
) => {
  return async (dispatch: any, getState: any) => {
    try {
      let pendingTransactionSnapshot;
      const pendingTransactionQuery = firebase
        .firestore()
        .collection("pendTransactions")
        .orderBy("date", "desc")
        .limit(pendTransactionDataLimit);

      const pendTransactionList: PendingTransactionModel[] =
        getState().pendTransactionStore.pendTransaction;

      const usersList: UserModel[] =
        getState().userStore.users.length > 0 ? getState().userStore.users : [];
      const newPendingTransactionList: PendingTransactionModel[] = [];

      updatePendTransactionLoadingState(dispatch, true);

      if (paginationStartAfter) {
        pendingTransactionSnapshot = await pendingTransactionQuery
          .startAfter(paginationStartAfter)
          .get();
      } else {
        pendingTransactionSnapshot = await pendingTransactionQuery.get();
      }
      if (pendingTransactionSnapshot) {
        const userIdList: string[] = [];
        pendingTransactionSnapshot.forEach((eachDoc: any) => {
          const eachTransaction = eachDoc.data() as PendingTransactionModel;
          newPendingTransactionList.push(eachTransaction);

          let userPresent = false;
          usersList.map((eachUser) => {
            if (eachUser.id === eachTransaction.userId) {
              userPresent = true;
            }
            return null;
          });

          if (!userPresent) {
            if (!userIdList.includes(eachTransaction.userId)) {
              userIdList.push(eachTransaction.userId);
            }
          }
        });

        const newUserList: UserModel[] = [];
        let lastCursor: Date | string = "";

        if (newPendingTransactionList.length > 0) {
          if (newPendingTransactionList.length === pendTransactionDataLimit) {
            lastCursor =
              newPendingTransactionList[pendTransactionDataLimit - 1].date;
          }

          dispatch({
            type: "UPDATE_PEND_TRANSACTION_LIST",
            payload: {
              pendTransaction: pendTransactionList.concat(
                newPendingTransactionList
              ),
              lastCursor: lastCursor,
            },
          });
        } else {
          dispatch({
            type: "UPDATE_PEND_TRANSACTION_LIST",
            payload: {
              pendTransaction: pendTransactionList,
              lastCursor: lastCursor,
            },
          });
        }

        if (userIdList.length > 0) {
          while (userIdList.length > 0) {
            const spliceUserIdList = userIdList.splice(
              0,
              pendTransactionDataLimit
            );
            const userSnapshot = await firebase
              .firestore()
              .collection("users")
              .where("id", "in", spliceUserIdList)
              .get();
            if (userSnapshot) {
              userSnapshot.forEach((eachUser: any) => {
                const eachUserData = eachUser.data() as UserModel;
                newUserList.push(eachUserData);
              });
            }
          }

          dispatch({
            type: "UPDATE_USER_LIST",
            payload: {
              users: usersList.concat(newUserList),
            },
          });
        }
      }

      updatePendTransactionLoadingState(dispatch, false);
    } catch (err: any) {
      updatePendTransactionLoadingState(dispatch, false);
    }
  };
};

const updatePendTransactionLoadingState = (dispatch: any, loading: boolean) => {
  dispatch({
    type: "UPDATE_PEND_TRANSACTIONS_LOADING",
    payload: {
      loading: loading,
    },
  });
};

export const clearPendTransaction = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_PEND_TRANSACTION_LIST",
      payload: {
        pendTransaction: [],
        lastCursor: "",
      },
    });
  };
};

export const updateSelectedPendTransaction = (
  user: UserModel,
  pendTransaction: PendingTransactionModel
) => {
  return (dispatch: any) => {
    dispatch({
      type: "UPDATE_SELECTED_PEND_TRANSACTION",
      payload: {
        selectedTransaction: {
          user,
          pendTransaction,
        },
      },
    });
  };
};

export const getPendTransaction = async (selectedId: string) => {
  try {
    if (firebase.auth().currentUser?.uid && selectedId) {
      const pendTransactionQuery = await firebase
        .firestore()
        .collection("pendTransactions")
        .doc(selectedId)
        .get();

      if (pendTransactionQuery.exists) {
        const currentTransaction =
          pendTransactionQuery.data() as PendingTransactionModel;
        const userQuery = await firebase
          .firestore()
          .collection("users")
          .doc(currentTransaction.userId)
          .get();

        let ngoQuery;
        let rewardQuery;
        if (currentTransaction.doneeId) {
          ngoQuery = await firebase
            .firestore()
            .collection("ngos")
            .doc(currentTransaction.doneeId)
            .get();
        } else if (currentTransaction.rewardId) {
          rewardQuery = await firebase
            .firestore()
            .collection("rewards")
            .doc(currentTransaction.rewardId)
            .get();
        }

        if (userQuery.exists) {
          return {
            pendTransaction: currentTransaction,
            user: userQuery.data() as UserModel,
            ngo: ngoQuery?.exists ? (ngoQuery.data() as Ngo) : null,
            reward: rewardQuery?.exists ? (rewardQuery.data() as Reward) : null,
          };
        } else {
          return "User cannot be found";
        }
      } else {
        return "Transaction cannot be found";
      }
    } else {
      return "Unknown error, please contact developer at info@arusoil.com if this continues";
    }
  } catch (err) {
    return "Unknown error, please contact developer at info@arusoil.com if this continues";
  }
};

export const deletePendTransactionById = async (
  selectedTransaction: {
    user: UserModel;
    pendTransaction: PendingTransactionModel;
  },
  ngo?: Ngo | null,
  tngDisbursementId?: string
) => {
  try {
    if (selectedTransaction.user.id && selectedTransaction.pendTransaction.id) {
      const transactionRef = firebase
        .firestore()
        .collection("transactions")
        .doc();
      const newTransactionId = transactionRef.id;
      const transactionModel: TransactionModel = {
        id: newTransactionId,
        type: "CREDIT",
        date: moment().toDate(),
        userId: selectedTransaction.user.id,
        scheduleId: "",
        receipt: "",
        amount: selectedTransaction.pendTransaction.amount,
      };
      if (selectedTransaction.pendTransaction.doneeId) {
        transactionModel["doneeId"] =
          selectedTransaction.pendTransaction.doneeId;
      } else if (selectedTransaction.pendTransaction.rewardId) {
        transactionModel["rewardId"] =
          selectedTransaction.pendTransaction.rewardId;
      }

      if (selectedTransaction.pendTransaction.tng)
        transactionModel["tngTransactionId"] =
          selectedTransaction.pendTransaction.id;
      if (tngDisbursementId)
        transactionModel["tngDisbursementId"] = tngDisbursementId;

      await transactionRef.set(transactionModel);
      await firebase
        .firestore()
        .collection("pendTransactions")
        .doc(selectedTransaction.pendTransaction.id)
        .delete();

      let content = `Hi ${
        selectedTransaction.user.name
      },<br><br>Your request to withdraw an amount of RM${
        selectedTransaction.pendTransaction.amount
      } of your account balance on Arus Oil to ${
        selectedTransaction.pendTransaction.id
          ? ngo?.name
          : "your provided bank account details"
      } has been fulfilled. For any questions, please do not hesitate to contact +6013-7311007 or contact our email at info@arusoil.com.<br><br>Thanks,<br>Arus Oil`;
      await axios.post(
        "https://api.enginemailer.com/RESTAPI/Submission/SendEmail",
        {
          UserKey: process.env.REACT_APP_EMAIL_SEND_API,
          ToEmail: selectedTransaction.user.email,
          SenderEmail: "noreply@arusoil.com",
          SenderName: "Arus Oil",
          Subject: "[Arus Oil] Withdrawal Updates",
          SubmittedContent: content,
        }
      );
    } else {
      return "Transaction cannot be found";
    }
  } catch (err) {
    return "Unknown error, please contact developer at info@arusoil.com if this continues";
  }
};
