export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const renderWorkspaceSettings = (userId: string) => {
  const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
  return {
    apiEndpoint: isProd
      ? "https://api.arusoil.com/api/data"
      : "https://api-dev.arusoil.com/api/data",
    apiEndpointType: "firebase",
    assetEndpoint: isProd
      ? "https://firebasestorage.googleapis.com/v0/b/arusoil-web.appspot.com/o/"
      : "https://firebasestorage.googleapis.com/v0/b/arusoil-web-dev.appspot.com/o/",
    apiUserToken: userId,
  };
};
